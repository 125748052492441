:root {
  --progressBar: #aa00ff;
}

html,
body {
  font-family: "Alegreya Sans", sans-serif;
  scroll-behavior: smooth;
}

/* Custom scroll bar */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f2f6ff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:vertical {
  background: linear-gradient(to bottom, #4a148c 10%, #aa00ff 100%);
  border: 1px solid #f2f6ff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(to right, #4a148c 10%, #aa00ff 100%);
  border: 1px solid #f2f6ff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: #aa00ff;
  border: none;
  border-radius: 4px;
}

/* Support for custom scroll bar for Firefox */
@supports (scrollbar-color: #f2f6ff #4a148c) {
  * {
    scrollbar-color: #f2f6ff #4a148c !important;
    scrollbar-width: thin !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-variant-numeric: lining-nums; /** to fix vertical align number problem with current font **/
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.camlambooks-bot {
  position: fixed !important;
  bottom: 8px !important;
  right: 20px !important;
}

@media only screen and (max-width: 899px) {
  .camlambooks-bot {
    bottom: 30px !important;
    right: 8px !important;
  }
}

@media only screen and (max-width: 396px) {
  .camlambooks-bot {
    bottom: 30px !important;
    right: 0 !important;
  }
}
