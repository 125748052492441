#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--progressBar);

  position: absolute;
  z-index: 1031;
  left: 0;
  bottom: -5px;

  width: 100%;
  height: 3px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--progressBar), 0 0 5px var(--progressBar);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
